import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'tab-home',
    loadChildren: () => import('./pages/tab-home/tab-home.module').then( m => m.TabHomePageModule)
  },
  {
    path: 'tab-deals',
    loadChildren: () => import('./pages/tab-deals/tab-deals.module').then( m => m.TabDealsPageModule)
  },
  {
    path: 'tab-events',
    loadChildren: () => import('./pages/tab-events/tab-events.module').then( m => m.TabEventsPageModule)
  },
  {
    path: 'tab-jobs',
    loadChildren: () => import('./pages/tab-jobs/tab-jobs.module').then( m => m.TabJobsPageModule)
  },
  {
    path: 'tab-more',
    loadChildren: () => import('./pages/tab-more/tab-more.module').then( m => m.TabMorePageModule)
  },
  {
    path: 'video-detail',
    loadChildren: () => import('./pages/video-detail/video-detail.module').then( m => m.VideoDetailPageModule)
  },
  {
    path: 'event-detail',
    loadChildren: () => import('./pages/event-detail/event-detail.module').then( m => m.EventDetailPageModule)
  },
  {
    path: 'news-detail',
    loadChildren: () => import('./pages/news-detail/news-detail.module').then( m => m.NewsDetailPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
